<template>
  <input
    v-model="value"
    novalidate
    :type="type"
    :class="[
      $style.input,
      invalid && $style.input_invalid,
      isActive && $style.isActive,
    ]"
  />
</template>

<script lang="ts" setup>
import __MACROS_useVModel from "/vue-macros/define-models/use-vmodel";
defineEmits(["update:modelValue"]);
defineProps({
  type: { default: 'text' },
  invalid: { type: Boolean, default: false },
  isActive: { type: Boolean, default: false },
  modelValue: null
})

const { modelValue } = __MACROS_useVModel("modelValue")

const value = computed<string>({
  get: () => modelValue.value,
  set: (value) => {
    modelValue.value = `${value}`
  },
})
</script>

<style lang="scss" module>
.input {
  width: 100%;
  height: 40px;
  padding: 0 15px;
  color: var(--text-dark-color);
  font-size: 16px;
  line-height: normal;
  background-color: #f0f0f0;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  outline: 2px solid transparent;
  outline-offset: -2px;
  transition:
    border-color 0.3s,
    outline-color 0.3s;
  -webkit-appearance: none;
  appearance: none;

  &:read-only:not(&.isActive) {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &::placeholder {
    color: var(--meta-color);
  }

  @include interact {
    outline-color: var(--accent-color);
  }

  &_invalid {
    border-color: var(--danger-color);
  }
}
</style>
